import { FetchFn, PlatformEnvData, PlatformLogger, ViewerAppsUrls, CommonConfig } from '@wix/thunderbolt-symbols'
import { BatchedUpdateFunction, BootstrapData, ViewerAPI } from '../types'
import { createPlatformAPI } from './index'
import moduleLoaderFactory from './loadModules'
import { batchUpdateFactory } from './batchUpdate'
import { platformLoggerCreator } from './platformLoggerFactory'
import importScriptsFactory from './importScriptsFactory'
import type { GetModels } from './types'

declare const self: {
	importScripts: (url: string) => void
	onmessage: (msg: MessageEvent) => void
	fetch: FetchFn
	location: Location
	commonConfig: CommonConfig
}

if (self.location && self.location.protocol === 'blob:') {
	/*  blob protocol is used to overcome CORS issue when creating WebWorker.
		fetch will not apply host protocol to requests starting with '//' when host protocol is blob so it must be fixed
		manually */

	const originalFetch = self.fetch.bind(self)

	self.fetch = (url: string, requestInit?: RequestInit) => {
		if (url.startsWith('//')) {
			url = `https:${url}`
		} else if (url.startsWith('/')) {
			url = `${self.location.origin}${url}`
		}

		return originalFetch(url, requestInit)
	}
}

const { initPlatformOnSite, runPlatformOnPage } = createPlatformAPI()
export function initWorkerOnSite({
	platformEnvData,
	appsUrlData,
	componentSdksUrl,
	scriptsCache = {},
}: {
	platformEnvData: PlatformEnvData
	appsUrlData: ViewerAppsUrls
	componentSdksUrl: string
	scriptsCache?: { [_scriptUrl: string]: () => any }
}) {
	const logger = platformLoggerCreator({ biData: platformEnvData.bi, appsUrlData, url: platformEnvData.location.rawUrl, isSSR: platformEnvData.window.isSSR, debug: platformEnvData.site.mode.debug })
	const moduleLoader = moduleLoaderFactory({ importScripts: importScriptsFactory(logger), scriptsCache })

	initPlatformOnSite({ moduleLoader, componentSdksUrl, logger, platformEnvData })
}

export async function runWorkerOnPage({
	bootstrapData,
	viewerAPI,
	scriptsCache = {},
	modelsProviderFactory,
}: {
	bootstrapData: BootstrapData
	viewerAPI: ViewerAPI
	scriptsCache?: { [_scriptUrl: string]: () => any }
	modelsProviderFactory: (logger: PlatformLogger) => GetModels
}) {
	const {
		appsUrlData,
		platformEnvData: {
			commonConfig,
			window: { isSSR },
			bi: biData,
			location: { rawUrl: url },
			site: {
				mode: { debug },
			},
		},
	} = bootstrapData

	const logger: PlatformLogger = platformLoggerCreator({ biData, appsUrlData, url, isSSR, debug })
	const importScripts = importScriptsFactory(logger)
	const moduleLoader = moduleLoaderFactory({ importScripts, scriptsCache })
	self.commonConfig = commonConfig
	const flushes: Array<() => void> = []

	const createBatchedUpdate = (updateFunc: BatchedUpdateFunction) => {
		const { batchUpdate, flushUpdates } = batchUpdateFactory(updateFunc)
		flushes.push(flushUpdates)
		return batchUpdate
	}
	viewerAPI.updateProps = createBatchedUpdate(viewerAPI.updateProps)
	viewerAPI.updateStyles = createBatchedUpdate(viewerAPI.updateStyles)

	await runPlatformOnPage({
		bootstrapData,
		viewerAPI,
		moduleLoader,
		importScripts,
		logger,
		getModels: modelsProviderFactory(logger),
	})

	flushes.forEach((flush) => flush())
}
